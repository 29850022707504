import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Image from '../components/Image';
import ImageSlider from '../components/ImageSlider';
import SimilarProperties from '../components/SimilarProperties';
import PageHelmet from '../components/PageHelmet';
import { HTMLContent } from '../components/Content';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const PropertyListingTemplate = ({
  pageData,
  similarProperties,
  helmet,
}) => {
  const [imagesModal, setImagesModalStatus] = useState({
    isOpen: false,
    index: 0,
  });

  const toggleImagesModal = (index) => {
    if (imagesModal.isOpen) {
      return setImagesModalStatus({ isOpen: false, index: 0 });
    }
    return setImagesModalStatus({ isOpen: true, index });
  };

  const renderDescription = (description) => {
    if (!!description) {
      return <HTMLContent content={description} />;
    }
    return <div>Enter description</div>;
  };
  const renderImageSlider = (images) => {
    if (!!images) {
      return (
        <ImageSlider
          sliderClass='property-slider'
          sliderText={{
            photo: 'All Photos',
            video: 'Videos',
            floorplan: 'Floorplan',
          }}
          images={images}
          floorplan={pageData.floorplan}
          video={pageData.videoLink}
          imagesModal={imagesModal}
          toggleImagesModal={toggleImagesModal}
        />
      );
    }
    return <div>IMAGE SLIDER HERE</div>;
  };

  const seoObject = {
    metaTitle:
      !!pageData.seoObject && !!pageData.seoObject.metaTitle
        ? pageData.seoObject.metaTitle
        : pageData.title,
    metaDescription:
      !!pageData.seoObject && !!pageData.seoObject.metaDescription
        ? pageData.seoObject.metaDescription
        : pageData.short_description,
  };

  return (
    <section className='property-listing-page'>
      {helmet || ''}
      <PageHelmet seoObject={seoObject} />
      <div className='container content'>
        <div className='columns'>
          <div className='column is-4 vertically-centered is-flex property-title-section mobile--hide'>
            <div className='property-title-container'>
              <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
                {pageData.title}
              </h1>
              <hr className='hr-black' />
              <p>{pageData.short_description}</p>
            </div>
          </div>
          <div className='column is-8 property-listing-main-image'>
            <Image
              imageInfo={{
                image: pageData.featuredImage,
                alt: `${pageData.title} featured image`,
              }}
              style={{ maxHeight: '70vh' }}
            />
            <div
              onClick={() => toggleImagesModal()}
              className='btn btn-main property-tour-button'>
              Tour this property
            </div>
          </div>
        </div>
      </div>

      <div className='container content'>
        <h2 className='mobile-property-title desktop--hide'>
          {pageData.title}
        </h2>
        <div className='columns description-section'>
          <div className='column is-6 is-flex'>
            {renderDescription(pageData.description_1)}
          </div>
          <div className='column is-6 is-flex'>
            {renderDescription(pageData.description_2)}
          </div>
        </div>
      </div>

      <div className='container content tour-slider'>
        <h2 className='title'>Tour this property</h2>
        {renderImageSlider(pageData.photos)}
      </div>

      <div className='full-width similar-properties-section'>
        <div className='section'>
          <div className='container content'>
            <h2 className='title similar-properties-title'>
              View similar properties
            </h2>
          </div>
        </div>
        {!!similarProperties ? (
          <SimilarProperties
            properties={similarProperties}
            title={pageData.title}
            price={pageData.startingPrice}
            propertyObject='property_data_en'
          />
        ) : null}
      </div>
    </section>
  );
};

const PropertyListing = ({ data, location }) => {
  const { title, siteUrl } = useSiteMetadata();
  const { frontmatter } = data.markdownRemark;
  const pageData = R.merge(frontmatter, frontmatter.property_data_en);
  const bookingData =
    !!location.state && !!location.state.checkIn ? location.state : null;
  const metaTitle =
    !!pageData.seoObject && !!pageData.seoObject.metaTitle
      ? pageData.seoObject.metaTitle
      : frontmatter.title;
  const metaDescription =
    !!pageData.seoObject && !!pageData.seoObject.metaDescription
      ? pageData.seoObject.metaDescription
      : pageData.short_description;
  return (
    <Layout>
      <PropertyListingTemplate
        location={location}
        pageData={pageData}
        similarProperties={data.allMarkdownRemark.edges}
        bookingData={bookingData}
        helmet={
          <MetaComponent
            title={metaTitle}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={metaDescription}
            link={`${siteUrl}${location.pathname}`}
            image={`${pageData.featuredImage}`}
          />
        }
      />
    </Layout>
  );
};

PropertyListing.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PropertyListing;

export const pageQuery = graphql`
  query propertyListingByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        featuredImage
        title
        property_data_en {
          short_description
          description_1
          description_2
          seoObject {
            metaTitle
            metaDescription
          }
        }
        videoLink
        floorplan {
          image
        }
        photos {
          label
          image
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "property-listing" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            property_data_en {
              short_description
            }
            featuredImage
          }
        }
      }
    }
  }
`;
